.reset_popup table th {
    vertical-align: middle;
    color: #8d8d8d;
    padding-right: 10px;
}
.reset_popup table td {
    padding: 6px 0 5px 0;
    color: #474747;
    height: 28px;
}

.reset_popup .divider {
    width: 25px;
    height: auto;
    display: inline-block;
}

.reset_popup .reset_password_error {
    color: red;
    line-height: 13px;

}

.reset_popup .error_txt3 {
    left: initial;
}


