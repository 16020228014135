.edit_led_cabinet_wrap .ui-dialog {
    z-index: 110 !important;
}

.edit_led_cabinet_wrap .ui-dialog-overlay {
    z-index: 106;
}

.edit_led_cabinet_wrap .devicepop_wrap {
    width: 100%;
    height: 100%;
    position: relative;
}

.edit_led_cabinet_wrap .device_loading_wrap {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex; 
    justify-content: center; 
    align-items: center;
}