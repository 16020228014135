.ruleset_add_rule_area {
    background-color: #EAEAEB;
    width: 100%;
    height: 70px;
    text-align: center;
}

.ruleset_add_rule_area > div {
    display: inline-block;
    width: 40px;
    height: 40px;
    margin-top: 15px;
    background: url('./images/btn_add.png') no-repeat 0 center;
    cursor: pointer;
}

.rightButton .verification_mode {
    margin-top: 32px;
}

.verification_mode .react-datepicker-popper{
    z-index: 1000;
}


span.ruleset-unschedulable {
    padding-left: 20px;
    background: url('../../images/icon/widget_ic_devi_warning.png') no-repeat 0 0;
}

.ruleset_rightbutton_wrap .quest_img_noti a {
    width: 20px;
    height: 20px;
    display: inline-block;
    vertical-align: middle;
    background: url('../../images/img/qu_notice.png') no-repeat;
}

.ruleset_rightbutton_wrap .opacity_notice_wrap {
    background: rgba(224,224,224,0.85);
    border: 1px solid #fff;
    color: #000;
    line-height: 22px;
    position: absolute;
	width: 440px;
	top: 45px;
	right: 0;
    padding: 10px 20px;
    margin-right: 20px;
	z-index: 2000;
}

.ruleset.no_data_wrap {
    width: 100%;
    text-align: center;
    margin-top: 20px !important;
}

.ruleset.no_data_wrap > div > img{
    margin-bottom: 24px;
}
.ruleset.no_data_wrap > div > span{
    font-size: 12px;
    color: #656565;
    line-height: 18px;
    font-family: Tahoma;
    display: block;
}

.ruleset_list_view .ReactTable .rt-tbody .rt-tr-group {
    padding: 0px;
}

.ruleset_list_view .ReactTable .rt-tbody .rt-tr-group .rt-td {
    padding: 10px 0px 10px 10px;
}