.list_column_data {
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.list_column_data:hover{
    text-decoration: underline;
}
.role_list_view {
    width: 100%;
    height: calc(100% - 68px);
    display: inline-flex;
    flex-direction: column;
}
.role_list_view .role_table_body {
    overflow-x: hidden;
}
.role_list_view .role_tr_group {
    padding-left: 0px !important;
    padding-right: 0px !important;
    max-height: 40px;
    padding: 0px !important;
    height: 40px !important;
    cursor: pointer;
}
.role_list_view .role_tr_group_selected {
    padding-left: 0px !important;
    padding-right: 0px !important;
    max-height: 41px;
    padding: 0px !important;
    height: 40px !important;
    background: #e6f2ff;
    cursor: pointer;
}
.ReactTable .rt-tbody .role_tr_group:last-child {
    border-bottom: 1px solid #f2f2f2;
}
.role_list_view .role_td_props {
    padding-left: 10px !important;
    padding-right: 10px !important;
    border-right: none !important;
}
.organization_group_list_view {
    width: 100%;
    height: calc(100% - 68px);
    display: inline-flex;
    flex-direction: column;
}
.organization_group_list_view .organization_group_table_body {
    overflow-x: hidden;
}
.organization_group_list_view .organization_group_tr_group {
    padding-left: 0px !important;
    padding-right: 0px !important;
    max-height: 40px;
    padding: 0px !important;
    height: 40px !important;
    cursor: pointer;
}
.organization_group_list_view .organization_group_tr_group_selected {
    padding-left: 0px !important;
    padding-right: 0px !important;
    max-height: 41px;
    padding: 0px !important;
    height: 40px !important;
    background: #e6f2ff;
    cursor: pointer;
}
.ReactTable .rt-tbody .organization_group_tr_group:last-child {
    border-bottom: 1px solid #f2f2f2;
}
.organization_group_list_view .organization_group_td_props {
    padding-left: 10px !important;
    padding-right: 10px !important;
    border-right: none !important;
}
.ldap_list_view {
    width: 100%;
    height: calc(100% - 68px);
    display: inline-flex;
    flex-direction: column;
}
.ldap_list_view .ldap_table_body {
    overflow-x: hidden;
}
.ldap_list_view .ldap_tr_group {
    padding-left: 0px !important;
    padding-right: 0px !important;
    max-height: 54px;
    padding: 0px !important;
    height: 53px !important;
    cursor: pointer;
}
.ldap_list_view .ldap_tr_group_selected {
    padding-left: 0px !important;
    padding-right: 0px !important;
    max-height: 54px;
    padding: 0px !important;
    height: 53px !important;
    background: #e6f2ff;
    cursor: pointer;
}
.ReactTable .rt-tbody .ldap_tr_group:last-child {
    border-bottom: 1px solid #f2f2f2;
}
.ldap_list_view .ldap_td_props {
    padding-left: 10px !important;
    padding-right: 10px !important;
    border-right: none !important;
}

.user_list_view {
    width: 100%;
    height: calc(100% - 68px);
    display: inline-flex;
    flex-direction: column;
}

.user_list_view .on {
    background: #e6f2ff !important;
}

.user_list_view .ReactTable .rt-tbody .rt-tr-group {padding: 0px;}
.user_list_view .ReactTable .rt-tbody .rt-tr-group .rt-td {padding: 10px 0px 10px 10px;}
.confirm_popup_wrap table {width: 100%;}
.confirm_popup_wrap table td {padding:6px 0 5px 0;vertical-align:middle;color:#474747;height:28px;}
.user_pop_list table tr td {height: 20px; border: 1px solid #f2f2f2;vertical-align:middle;text-align:left;padding:9px 10px 9px 10px;line-height:20px;position:relative;color:#797979;
    max-width: 60px;text-overflow:ellipsis;white-space:nowrap;overflow:hidden;}
.user_pop_list table tr:last-child td {border-bottom: 1px solid #f2f2f2;}
.user_pop_list table tr:hover td {background:#f3f9ff;border-bottom:1px solid #f3f9ff;}
.user_pop_list table tr td.icon img {margin-right:4px;}
.user_pop_list table tr.on td,
.user_pop_list table tr:active td {background:#e6f2ff;border-bottom:1px solid #dae6f2;color:#2a2a2a;}
.user_pop_list table tr th:hover {background:#f3f9ff;border-bottom:1px solid #f3f9ff;}
.user_pop_list table tr th.on {background-color:#e6f2ff;}