.preset_popup_wrap .ui-dialog {
    z-index: 110 !important;
}

.preset_popup_wrap .ui-dialog-overlay {
    z-index: 106;
}

.preset_result_wrap .preconfig_result_pop .sub_list_head > table {
    table-layout: fixed;
    width: 700px;
}

.preset_result_wrap .preconfig_result_pop .sub_list_head thead {
    /* float: left; */
    /* width: 100%; */
    border-top: 1px solid #f3f3f3;
    border-bottom: 1px solid #f3f3f3;
}

.preset_result_wrap .preconfig_result_pop .sub_list_head thead th {
    padding-left: 13px;
    height: 22px;
}

.preset_result_wrap .preconfig_result_pop .sub_list_head tbody td {
    padding-left: 13px;
}

.preset_result_wrap .preconfig_result_pop .sub_list_head tbody tr {
    border-bottom: 1px solid #f3f3f3;
    width: 700px;
}

.preset_result_wrap .preconfig_result_pop.basic th {
    height: 22px;
}

.preset_result_wrap .preconfig_result_pop .sub_list_head tbody {
    overflow-y: auto;
    overflow-x: hidden;
    display: block;
    /* float: left; */
    width: 700px;
    max-height: 400px;
}

.preset_result_wrap .preconfig_result_pop.basic tr {
    height: 30px;
}



.preconfig_result_pop .detail_view {position:relative;}
.preconfig_result_pop .detail_view.t_line {border-top:1px solid #f4f4f4;}
.preconfig_result_pop .detail_view table {width:100%;}
.preconfig_result_pop .detail_view table th {vertical-align:middle;color:#8d8d8d;padding-right:10px;}
.preconfig_result_pop .detail_view.user_info table th {font-size:13px}
.preconfig_result_pop .detail_view table td {padding:6px 0 5px 0;vertical-align:middle;color:#474747;height:28px;}
.preconfig_result_pop .detail_view table tr.bullet_line td {padding:2px 0 1px 0;color:#474747;}
.preconfig_result_pop .detail_view table tr.va_t th {vertical-align:top;padding-top:11px;}
.preconfig_result_pop .detail_view table td label {color:#474747;display:inline-block;}
.preconfig_result_pop .detail_view table td.icon img {vertical-align:middle;margin-right:4px;}
.preconfig_result_pop .detail_view table td textarea {}
.preconfig_result_pop .detail_view table th img {margin-left:6px;vertical-align:middle;}
.preconfig_result_pop .detail_view table th .bull {display:inline-block;background:url('../../../../images/icon/bullet_3.png') no-repeat 0 6px;padding-left:13px;}
.preconfig_result_pop .detail_view table th h4 {margin:0;font-size:16px;color:#676767;font-weight:normal;margin-bottom:10px;}
.preconfig_result_pop .detail_view table th h4 > span {color:#b8b8b8;font-size:11px;display:inline-block;padding-left:30px;background:url('../../../../images/icon/bullet_2.png') no-repeat 17px ;}
.preconfig_result_pop .detail_view .space {display:block}
.preconfig_result_pop .detail_view .speed_controler {margin-top:0;}
.preconfig_result_pop .detail_view .speed_controler > span {width:273px;}
.preconfig_result_pop .detail_view .speed_controler > span > .txt {font-size:10px;top:20px;background:none;color:#b3b4b5;}
.preconfig_result_pop .detail_view.tline {border-top:1px solid #f5f5f5;}
.preconfig_result_pop .detail_view table td .txt {color:#8d8d8d;font-size:12px;display:block;line-height:17px}
.preconfig_result_pop .detail_view table tr.click_layer > td {position:relative;height:115px}
.preconfig_result_pop .detail_view table tr.click_layer td div {position:absolute;left:355px;top:-38px;}
.preconfig_result_pop .detail_view table tr.click_layer td div table tr th {padding-left:20px;color:#474747;}
.preconfig_result_pop .detail_view table tr.click_layer td div table tr:first-child th {background:url('../../../../images/bg/bar_5.png') no-repeat 0 14px;padding-left:20px}

