.device_quick_control_popup_wrap .ui-dialog {
    /*left: 100% !important;*/
    /*
    width: 220px !important;
    height: 750px !important;
    */
    background: rgb(243, 243, 243);
    border: 1px solid #c5c5c5;
    scroll-behavior: auto;
}

.device_quick_control_popup_wrap .ui-dialog .ui-dialog-titlebar {
    border-bottom: 1px solid #ebebeb;
}

.device_quick_control_popup_wrap .quick_control_wrap {
    padding: 0px 28px; 
    overflow-y: auto;
    height: 90%;
}

.device_quick_control_popup_wrap .quick_control_wrap > ul > li {
    margin: 26px 0 0 0;
    display: block;
}

.device_quick_control_popup_wrap .quick_control_wrap > ul > li > div:first-child {
    color: #868686;
    font-size: 12px;
}

.device_quick_control_popup_wrap .quick_control_wrap > ul > li > div:nth-child(2) {
    margin: 5px 0 0 0;
    height: 28px;
}

.device_quick_control_popup_wrap .quick_control_wrap > ul > li > .one_btn_control,.two_btn_control {
    margin: 5px 0 0 0;
    height: 28px;
    text-align: center;
    white-space: nowrap;
}

.device_quick_control_popup_wrap .quick_control_wrap > ul > li .two_btn_control button {
    width: 68px;
    height: 28px;
    margin: 0;
    padding: 0;
    border: 1px solid #dde0e5;
    background: #fff;
    color: #363636;
}

/* .quick_control .control_btn button:active {border:1px solid #7188a0; background:#7188a0; color:#fff} */

.device_quick_control_popup_wrap .quick_control_wrap > ul > li .two_btn_control button:hover {
    background:#7188a0; 
    color:#fff;
}

.device_quick_control_popup_wrap .quick_control_wrap > ul > li .two_btn_control button:disabled {
    /* border-color: #b9b9b9;  */
    color: #b9b9b9;   
    background: #fff;
}

.device_quick_control_popup_wrap .quick_control_wrap > ul > li .two_btn_control button:first-child {
    border-left: 1px solid #dde0e5;
    border-radius: 15px 0 0 15px;
}

.device_quick_control_popup_wrap .quick_control_wrap > ul > li .two_btn_control button:last-child {
    border-left: 0;
    border-radius: 0 15px 15px 0;
}

.device_quick_control_popup_wrap .quick_control_wrap > ul > li .one_btn_control button {
    width: 136px;
    border-radius: 15px;
    margin: 0;
    padding: 0;
    border: 1px solid #dde0e5;
    background: #fff;
    color: #363636;
}

.device_quick_control_popup_wrap .quick_control_wrap > ul > li .one_btn_control button:disabled {
    color: #b9b9b9;   
    background: #fff;
}