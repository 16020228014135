.message_schedule_manager_wrap .contents_buttonwrap {
    height: 38px;
    position: relative;
    display: inline-block;
    width: 100%;
    margin-left: 20px;
}

.message_schedule_manager_wrap .contentsbox {
    position: relative;
    overflow: hidden;
}

.message_schedule_manager_wrap .contents {
    word-break: break-all;
}

.message_schedule_manager_wrap .schedule_message h2 {
    font-size: 16px;
    font-weight: normal;
    vertical-align: middle;
}
.message_schedule_manager_wrap .schedule_message > h2 {
    font-weight: normal;
    font-size: 16px;
    margin: 0;
    padding: 12px 24px 15px 24px;
    line-height: 27px;
}
.message_schedule_manager_wrap .schedule_message > div {
    padding: 0 0 0 24px;
}

.message_schedule_manager_wrap .message_editerbox {
    overflow-x: hidden;
    overflow-y: auto;
    height: 750px;
}

.message_schedule_manager_wrap .schedule_message .message_wrap {
    border: 1px solid
    #e4e4e4;
    width: 671px;
}

.message_schedule_manager_wrap .message_editer {
    background: #f1f2f3;
    padding: 9px;
}

.message_schedule_manager_wrap .base.select span > .arrow {
    display: inline-block;
    float: right;
    width: 10px;
    height: 20px;
    background: url('../../../images/button/select_down.png') no-repeat 0 10px;
    margin-left: 10px;
}
.message_schedule_manager_wrap .eventBgColor {
    background: #f1f2f3;
    border: 0px;
}
.message_schedule_manager_wrap .sp-replacer {
    margin: 0;
    overflow: hidden;
    cursor: pointer;
    padding: 4px;
    display: inline-block;
    *zoom: 1;
    *display: inline;
    border: solid 1px #91765d;
    background: #eee;
    color: #333;
    vertical-align: middle;
}

.message_schedule_manager_wrap .sp-preview, .sp-alpha, .sp-thumb-el {
    position: relative;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAIAAADZF8uwAAAAGUlEQVQYV2M4gwH+YwCGIasIUwhT25BVBADtzYNYrHvv4gAAAABJRU5ErkJggg==);
}
.message_schedule_manager_wrap .sp-preview {
    position: relative;
    width: 25px;
    height: 20px;
    border: solid 1px
    #222;
    margin-right: 5px;
    float: left;
    z-index: 0;
}

.message_schedule_manager_wrap .sp-preview-inner, .sp-alpha-inner, .sp-thumb-inner {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
.message_schedule_manager_wrap .sp-dd {
    padding: 2px 0;
    height: 16px;
    line-height: 16px;
    float: left;
    font-size: 10px;
}
.message_schedule_manager_wrap .message_area {
    overflow: hidden;
    position: relative;
    background: #fff;
    width: 671px;
    height: 300px;
    border-bottom: 1px solid
    #e4e4e4;
    border-top: 1px solid
    #e4e4e4;
    background: url('../../../images/bg/message_bg.png');
}
.message_schedule_manager_wrap .message_modify {
    overflow: hidden;
    padding: 9px;
    background:
            #fff;
}
.message_schedule_manager_wrap .message_modify textarea {
    border: 0;
    width: 97%;
    min-height: 41px;
    max-height: 50px;
    background:
            #fff;
    font-size: 16px;
    color:
            #909090;
}

.message_schedule_manager_wrap table {
    border-collapse: collapse;
    border-spacing: 0;
}

.message_schedule_manager_wrap .message_detail_setting > table > tbody > tr.tp20 > th {
    padding: 20px 0 10px 0;
}
.message_schedule_manager_wrap .message_detail_setting > table > tbody > tr > th {
    vertical-align: middle;
    color:
            #646464;
}
.message_schedule_manager_wrap .message_detail_setting > table > tbody > tr.line > td {
    padding: 20px 0;
    border-bottom: 1px solid
    #eeeff0;
}
.message_schedule_manager_wrap .message_detail_setting > table > tbody > tr > td {
    padding: 10px 0;
}
.message_schedule_manager_wrap #contentApproverTable th, td {
    color:
            #59616b !important;
}
.message_schedule_manager_wrap .warring {
    display: inline-block;
    background: url('../../../images/icon/bullet_1.png') no-repeat 0 3px;
    padding-left: 17px;
    color: #a9a9a9;
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}

.message_schedule_manager_wrap .schedule_message .lnb_view {
    margin-right: 10px;
    display: none;
    float: left;
}
.message_schedule_manager_wrap .lnb_view {
    width: 27px;
    height: 27px;
    background: url('../../../images/icon/lnb_view_normal.png') no-repeat;
    border: 0;
    border-radius: 14px;
}
.message_schedule_manager_wrap .base.un_radius img {
    padding-top: 2px;
}
.message_schedule_manager_wrap fieldset, img {
    border: 0;
}
.message_schedule_manager_wrap .base.un_radius {
    border-radius: 5px;
}

.message_schedule_manager_wrap .input_date > button:hover {
    background: url('../../../images/icon/calander_icon_hover.png');
    border: 0;
}
.message_schedule_manager_wrap .input_date > button {
    position: absolute;
    top: 3px;
    right: 3px;
    width: 22px;
    height: 22px;
    background: url('../../../images/icon/calander_icon_normal.png');
    border: 0;
}

.message_schedule_manager_wrap .message_wrap .on {
    background: #DADADA;
}


.message_schedule_manager_wrap .eventBgColor {
    background: #f1f2f3;
    border: 0px;
}

.message_schedule_manager_wrap .scheduleTime {
    width: 80px;
}

.message_schedule_manager_wrap .react-datepicker-wrapper {
    float:left;
}

.message_schedule_manager_wrap .message_monthly_wrap > div{
    width: 30px;
    height: 27px;
    float: left;
    text-align: center;
    border: 1px solid #fcfcfc;
}

.message_schedule_manager_wrap .repeatTypeMonCal.on {
    background-color: rgba(49, 150, 255, 0.59);
}


.message_area #messageArea > div{
    animation-timing-function: linear;
}


@keyframes slideLeft {
    from {

        transform: translateX(100%);

    }
    to {

        transform: translateX(-10%);
    }
}

@keyframes slideRight {
    from {

        transform: translateX(-10%);

    }
    to {

        transform: translateX(100%);
    }
}
@keyframes slideTop {
    from {
        margin-top: 5%;
        height:100%
    }
    to {
        margin-top: -5%;
        height: 0%
    }
}

@keyframes slideBottom {
    from {
        margin-top: -5%;


    }
    to {
        margin-top: 5%;

    }
}

@keyframes slideTopLeftRight {
    from {
        margin-top: 5%;
        height:100%
    }
    to {
        margin-top: -5%;
        height: 0%
    }
}

@keyframes slideLeftLeftRight {
    from {
        margin-left:30px;
        width : 100%
    }
    to {
        margin-left:-30px;
        width: 0%
    }
}

@keyframes slideRightLeftRight {
    from {
        margin-left:-30px;
        width : 100%
    }
    to {
        margin-left:30px;
        width: 0%
    }
}

@keyframes slideTopLeftRight {
    from {
        margin-top: 300px;
    }
    to {
        margin-top: -300px;
    }
}

@keyframes slideBottomLeftRight {
    from {
        margin-top: -300px;
    }
    to {
        margin-top: 300px;
    }
}
