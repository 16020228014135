.edit_device_pop_wrap .ui-dialog {
    z-index: 110 !important;
}

.edit_device_pop_wrap .ui-dialog-overlay {
    z-index: 106;
}

.edit_device_pop_wrap .edit_device_wrap {
    position: relative;
    padding: 0px 10px;
    width: 845px;
    height: 600px;
    overflow-y: hidden;
}

.edit_device_pop_wrap .edit_device_wrap .changed {
    /* font-size: 13px; */
    color: #e55b48 !important;
}

.edit_device_pop_wrap .edit_device_wrap .response_warning {
    background:url('../../../../images/icon/bullet_1.png') no-repeat 0 14px !important;
    padding-left:16px;
    color:#a9a9a9;
    background-position-y: center !important;
}

.edit_device_pop_wrap .edit_device_wrap .device_edit_panel_wrap {
    height: 530px;
    overflow: auto;
}

.edit_device_pop_wrap .edit_device_wrap .device_edit_panel_wrap .devicepop {
    margin-right: 10px;
    border-bottom: 1px solid #f4f4f4;
}

.edit_device_pop_wrap .edit_device_wrap .device_edit_panel_wrap .devicepop > h3 {
    font-size: 16px;
    font-weight: normal;
    margin: 0;
    color: #676767;
}

.edit_device_pop_wrap .edit_device_wrap .device_edit_panel_wrap .devicepop .system_restart_interval > span {
    margin-right: 10px;
}

.edit_device_pop_wrap .edit_device_wrap .color_button_wrap {
    margin: 0;
    overflow: hidden;
    cursor: pointer;
    padding: 4px;
    display: inline-block;
    border: solid 1px #91765d;
    background: #eee;
    color: #333;
    vertical-align: middle;
}

.edit_device_pop_wrap .edit_device_wrap .device_loading_wrap {
    position: absolute;
    width: 95%;
    height: 100%;
    display: flex; 
    justify-content: center; 
    align-items: center;
}