.detail_View_calendar .fc-day-grid-event {
    margin: 0;
    border: 0px none;
    height: 5px;

}

.detail_View_calendar .fc-today > span {
    width: 20px;
    height: 20px;
    display: inline-block;
    border-radius: 15px;
    top: 10px;
    left: 0;
    background: #00ddca;
    color: #fff;
}

.detail_View_calendar .fc-center > h2 {
    font-size: 16px;
    color: #303030;
    width: auto;
}

.detail_View_calendar .fc-toolbar .fc-center {
    display: inline-block;
}

.detail_View_calendar .channel_wrap {
    position: relative;
    text-align: center;
    color: #787878;
    font-size: 18px;
    border-bottom: 1px solid #d6d6d6;
    line-height: 43px;
}

.detail_View_calendar > div > div{
    word-break: break-all;
}



/* for schedule detail popup*/
.calendar_wrap_pop .channel_wrap {
    font-size: 22px;
    margin-bottom: 20px;
}
.calendar_wrap_pop .calendar_arrow_pop {
    width: 20px;
    height: 40px;
    border: 0;
    border-radius: 9px;
    padding: 0;
    background: none;
}
.calendar_wrap_pop .channel_wrap > div {
    margin-top: 0;
}
.calendar_wrap_pop .calendar_arrow_pop.prev2 > span {
    background: url('../../../images/button/date_select_pop_prev2.png') no-repeat 4px 4px;
}
.calendar_wrap_pop .calendar_arrow_pop.prev > span {
    background: url('../../../images/button/date_select_pop_prev.png') no-repeat 6px 4px;
}
.calendar_wrap_pop .calendar_arrow_pop.next {
    top: 5px;
    right: 4px;
}
.calendar_wrap_pop .calendar_arrow_pop.next > span {
    background: url('../../../images/button/date_select_pop_next.png') no-repeat 6px 4px;
}
.calendar_wrap_pop .calendar_arrow_pop.next2 > span {
    background: url('../../../images/button/date_select_pop_next2.png') no-repeat 5px 4px;
}
.calendar_wrap_pop .calendar_arrow_pop > span {
    width: 18px;
    height: 18px;
    display: inline-block;
}


.schedule_calendar_view .fc-unthemed .fc-today{background:none;}
.schedule_calendar_view .fc td, .schedule_calendar_view .fc th {border-style: none !important;text-align:center;}
.schedule_calendar_view .fc-today a {width:20px;height:20px;display:inline-block;border-radius:15px;top:0;left:0;background:#00ddca; color:#fff;}
.schedule_calendar_view .fc-event{border-radius: 0;}
.schedule_calendar_view .fc-day-grid-event{margin:0;padding:0}
.schedule_calendar_view .fc-prev-button, .schedule_calendar_view .fc-next-button{width: 22px;height: 22px;border: 0;border-radius: 9px;padding: 0;background: #f4f6f8;}
.schedule_calendar_view .scheduleWrapCalendarTitle {font-size:16px;color:#303030;width:auto;}
.schedule_calendar_view .fc-today-button{width: 70px;color: #848484;background: #fff; line-height: 26px; border: 1px solid #dde0e5; line-height: 24px; border-radius: 15px;}
.schedule_calendar_view .fc-today-button span{display: inline-block;width: 6px;height: 6px;border-radius: 3px;background: #00ddca;margin-right: 3px;vertical-align: 1px;}

/* for editing scheudle */
.schedule_calendar_view .fc-today-button span{display: inline-block;width: 6px;height: 6px;border-radius: 3px;background: #00ddca;margin-right: 3px;vertical-align: 1px;}
.schedule_calendar_view .fc-today-button.fc-button.fc-button-primary {width: 70px;color: #848484;background: #fff; line-height: 14px; border: 1px solid #dde0e5; border-radius: 15px;}
.schedule_calendar_view .fc-today-button.fc-button.fc-button-primary:hover {color: #000;}
.schedule_calendar_view .fc-prev-button {width: 22px;height: 22px;border: 0;border-radius: 9px;padding: 0;background: #f4f6f8;left:0;cursor:pointer;top:3px;float:left}
.schedule_calendar_view .fc-center > h2 {float: left}
.schedule_calendar_view .fc-next-button{width: 22px;height: 22px;border: 0;border-radius: 9px;padding: 0;background: #f4f6f8;right:0;cursor:pointer;top:3px;float:left}
.schedule_calendar_view .scheduleWrapCalendarTitle {position:relative;width:auto;text-align:center;}
.schedule_calendar_view {text-align:center;background:#fff;line-height:28px;padding:0 35px;border-radius:15px; position:relative;height:28px;width:auto;}
.schedule_calendar_view .fc-agendaDay-button{border:none; background:none;cursor:pointer;font-size:13px;}
.schedule_calendar_view .fc-agendaWeek-button{border:none; background:none;cursor:pointer;font-size:13px;}
.schedule_calendar_view .fc-month-button{border:none; background:none;cursor:pointer;font-size:13px;}
.schedule_calendar_view .fc-list-button{border:none; background:none;cursor:pointer;font-size:13px;}
.schedule_calendar_view .fc-toolbar {padding: 0;padding-top:20px;position: relative;height: 45px;border-bottom: 1px solid #eeeff0;margin-bottom:0;}
.schedule_calendar_view .fc-toolbar button.fc-state-active {color:#000;}
.fc-toolbar button:hover {color:#000;}
.schedule_calendar_view .fc-day-header {font-size:13px;font-weight:bold;}
.schedule_calendar_view .fc-day-header span.today {display: inline-block;line-height:20px;width: 22px;height: 22px;border-radius: 10px;font-weight: bold;text-align: center;background: #00ddca;color: #fff;}
.schedule_calendar_view .fc-day-header span.dailyToday {display: inline-block;height: 21px;background: #00ddca;border-radius: 10px;text-align: center;color: #fff;padding: 0 9px;line-height:20px;}
.schedule_calendar_view .fc-widget-header {border:0; height:40px;line-height:40px;}
.schedule_calendar_view .fc-minor td:first-child {border:0}
.schedule_calendar_view .fc-scroller.fc-day-grid-container {overflow: hidden !important;}


.popper,
.tooltip {
    position: absolute;
    background: rgb(75, 75, 75);
    color: white;
    min-width: 80px;
    border-radius: 3px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
    padding: 12px;
    text-align: center;
    z-index: 9999
}
.popper .popper__arrow,
.tooltip .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
}

.tooltip .tooltip-arrow,
.popper .popper__arrow {
    border-color: rgb(75, 75, 75);
}
.popper[x-placement^="top"],
.tooltip[x-placement^="top"] {
    margin-bottom: 6px;
}
.popper[x-placement^="top"] .popper__arrow,
.tooltip[x-placement^="top"] .tooltip-arrow {
    border-width: 5px 5px 0 5px;
    border-left-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    bottom: -5px;
    left: calc(50% - 5px);
    margin-top: 0;
    margin-bottom: 0;
}
.popper[x-placement^="bottom"],
.tooltip[x-placement^="bottom"] {
    margin-top: 6px;
}
.tooltip[x-placement^="bottom"] .tooltip-arrow,
.popper[x-placement^="bottom"] .popper__arrow {
    border-width: 0 5px 5px 5px;
    border-left-color: transparent;
    border-right-color: transparent;
    border-top-color: transparent;
    top: -5px;
    left: calc(50% - 5px);
    margin-top: 0;
    margin-bottom: 0;
}
.tooltip[x-placement^="right"],
.popper[x-placement^="right"] {
    margin-left: 6px;
}
.popper[x-placement^="right"] .popper__arrow,
.tooltip[x-placement^="right"] .tooltip-arrow {
    border-width: 5px 5px 5px 0;
    border-left-color: transparent;
    border-top-color: transparent;
    border-bottom-color: transparent;
    left: -5px;
    top: calc(50% - 5px);
    margin-left: 0;
    margin-right: 0;
}
.popper[x-placement^="left"],
.tooltip[x-placement^="left"] {
    margin-right: 6px;
}
.popper[x-placement^="left"] .popper__arrow,
.tooltip[x-placement^="left"] .tooltip-arrow {
    border-width: 5px 0 5px 5px;
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    right: -6px;
    top: calc(50% - 5px);
    margin-left: 0;
    margin-right: 0;
}