.event_condition_wrap .schedule_message > div {
    padding: 0 0 0 24px;
}

.event_condition_wrap .schedule_message .message_wrap {
    border: 1px solid
    #e4e4e4;
    width: 671px;
}

.event_condition_wrap .schedule_message .message_editer {
    background: #f1f2f3;
    padding: 9px;
    display: flex;
}

.event_condition_wrap .message_detail_setting > table > tbody > tr > th {
    vertical-align: middle;
    color: #646464;
}

.event_condition_wrap .message_detail_setting > table > tbody > tr > td {
    padding: 10px 0;
}

.event_condition_wrap .warring {
    display: inline-block;
    background: url('../../../images/icon/bullet_1.png') no-repeat 0 3px;
    padding-left: 17px;
    color: #a9a9a9;
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}

.event_condition_wrap .message_editer .on {
    background: #DADADA;
}

.event_condition_wrap .sp-replacer {
    margin: 0;
    overflow: hidden;
    cursor: pointer;
    padding: 4px;
    display: inline-block;
    *zoom: 1;
    *display: inline;
    background: #eee;
    color: #333;
    vertical-align: middle;
}

.event_condition_wrap .sp-preview, .sp-alpha, .sp-thumb-el {
    position: relative;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAIAAADZF8uwAAAAGUlEQVQYV2M4gwH+YwCGIasIUwhT25BVBADtzYNYrHvv4gAAAABJRU5ErkJggg==);
}

.event_condition_wrap .sp-preview {
    position: relative;
    width: 25px;
    height: 20px;
    border: solid 1px
    #222;
    margin-right: 5px;
    float: left;
    z-index: 0;
}

.event_condition_wrap .sp-preview-inner, .sp-alpha-inner, .sp-thumb-inner {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
.event_condition_wrap .sp-dd {
    padding: 2px 0;
    height: 16px;
    line-height: 16px;
    float: left;
    font-size: 10px;
}

.event_condition_wrap .thumbnail_box img {
    width: 172px;
    height: 172px;
}

.message_editer .color_picker_wrap > div:first-child {
    margin-right:30px;
}

@keyframes slideLeft {
    from {

        transform: translateX(100%);

    }
    to {

        transform: translateX(-10%);
    }
}

@keyframes slideRight {
    from {

        transform: translateX(-10%);

    }
    to {

        transform: translateX(100%);
    }
}
@keyframes slideTop {
    from {
        margin-top: 5%;
        height:100%
    }
    to {
        margin-top: -5%;
        height: 0%
    }
}

@keyframes slideBottom {
    from {
        margin-top: -5%;


    }
    to {
        margin-top: 5%;

    }
}

@keyframes slideTopLeftRight {
    from {
        margin-top: 5%;
        height:100%
    }
    to {
        margin-top: -5%;
        height: 0%
    }
}

@keyframes slideLeftLeftRight {
    from {
        margin-left:30px;
        width : 100%
    }
    to {
        margin-left:-30px;
        width: 0%
    }
}

@keyframes slideRightLeftRight {
    from {
        margin-left:-30px;
        width : 100%
    }
    to {
        margin-left:30px;
        width: 0%
    }
}

@keyframes slideTopLeftRight {
    from {
        margin-top: 300px;
    }
    to {
        margin-top: -300px;
    }
}

@keyframes slideBottomLeftRight {
    from {
        margin-top: -300px;
    }
    to {
        margin-top: 300px;
    }
}
