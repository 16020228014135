.rule_area {
	margin-bottom: 8px;
}

.rule_area .no_result_icon {
	vertical-align: middle;
    height: 15px;
	width: 23px;
	margin-top:12px;
	float: left;
}

.rule_area.no-result .no_result_icon {
	margin-top:12px;
	float: left;
    background: url('./images/icon_status_nor.png') no-repeat center center;
}

.ruleset_rule_title {
	width:100%;
	height: 40px;
	background-color: #EAEAEB;
}

.ruleset_rule_title > span {
	width: 85%;
	display: inline-block;
	overflow: hidden;
	text-overflow: ellipsis;
	vertical-align: middle;
	margin-left: 10px;
	margin-top:11px;
	color: black;
	font-size: 12px;
	font-weight: bold;
}

.ruleset_rule_title .option_btn {
	width: 14;
	height: 15px;
    border: 0px;
    vertical-align: middle;
	float:right;
	margin-top:13px;
    margin-right:10px;
    background: url('./images/btn_tab_more.png') no-repeat 0 0;
}

.ruleset_rule_title .fold_btn {
	width: 14px;
	height: 14px;
    border: 0px;
    vertical-align: middle;
	float:right;
	margin-top:13px;
    margin-right:10px;
    background: url('./images/btn_tab_collapse.png') no-repeat 0 0;
}

.ruleset_rule_title .fold_btn.fold {
    background: url('./images/btn_tab_expand.png') no-repeat 0 0;
}

.ruleset_rule {
	padding: 30px;
	padding-left: 31px;
	background-color: #F7F7F9;
	min-height: 608px;
}

.ruleset_rule.empty {
	background: #F7F7F9 url('./images/img_dragging.png') center center no-repeat;
}

.ruleset_rule_title .opacity_notice_wrap {
    background: rgba(224,224,224,0.85);
    border: 1px solid #fff;
    color: #000;
	line-height: 22px;
	position: absolute;
	width: 440px;
	top: 125px;
	left: 275px;
	right: 100px;
	padding: 10px 20px;
	z-index: 2000
}

.ruleset_rule_title .opacity_notice_wrap > table > tbody > tr > td {
    color: #000000;
    padding: 0 !important;
}