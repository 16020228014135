.error_txt2 {width: 100%; color: #f79394; line-height: 13px;}
.base.button:disabled {color: #c1c1c1; border-color: #f3f3f3; background: #f3f3f3; cursor: default;}
.base.un_radius {border-radius: 5px;}
.base.un_radius.select {border-radius: 5px;}
.base.button {width: 290px; padding-left: 9px; padding-right: 6px; text-align: left;}
.base.button.error {border-color: #f79394 !important;}

#tutorialBtn, .white_list_question, #mngGroupTutorialBtn {
    border: 0px;
    background: url(../../../images/button/tutorial_normal.png) no-repeat;
    width: 28px;
    height: 28px;
}

.mngGrouptutorial_wrap {
    position: absolute;
    top: 210px;
    left: 500px;
    background: rgba(0, 0, 0, 0);
    padding-bottom: 2px;
    border-radius: 3px;
    width: 200px;
    height: auto;
    white-space: pre-line;
    text-align: left
}

.mngGrouptutorial_wrap > div {
    position: relative;
    color: #b8b8b8;
    font-size: 11px;
    display: inline-block;
}

.user_pop_wrap {
    height: 100%;
    width: 750px;
    overflow-y: auto;
    overflow-x: hidden;
}

.user_pop_wrap .userpop .system_restart_interval > span {
    margin-right: 10px;
}

.user_detail_view {position:relative;}
.user_detail_view.t_line {border-top:1px solid #f4f4f4;}
.user_detail_view table {width:100%;}
.user_detail_view table th {vertical-align:middle;color:#8d8d8d;padding-right:10px;}
.user_detail_view.user_info table th {font-size:13px}
.user_detail_view table td {padding:6px 0 5px 0;vertical-align:middle;color:#474747;height:28px;}
.user_detail_view .notification {vertical-align: top;}
.user_detail_view table tr.bullet_line td {padding:2px 0 1px 0;color:#474747;}
.user_detail_view table tr.va_t th {vertical-align:top;padding-top:11px;}
.user_detail_view table td label {color:#474747;display:inline-block;}
.user_detail_view table td.icon img {vertical-align:middle;margin-right:4px;}
.user_detail_view table td textarea {}
.user_detail_view table th img {margin-left:6px;vertical-align:middle;}
.user_detail_view table th h4 {margin:0;font-size:16px;color:#676767;font-weight:normal;margin-bottom:10px;}
.user_detail_view .space {display:block}
.user_detail_view .speed_controler {margin-top:0;}
.user_detail_view .speed_controler > span {width:273px;}
.user_detail_view .speed_controler > span > .txt {font-size:10px;top:20px;background:none;color:#b3b4b5;}
.user_detail_view.tline {border-top:1px solid #f5f5f5;}
.user_detail_view table td .txt {color:#8d8d8d;font-size:12px;display:block;line-height:17px}
.user_detail_view table th h4 > span {color:#b8b8b8;font-size:11px;display:inline-block;padding-left:30px;background:url('../../../images/icon/bullet_2.png') no-repeat 17px ;}
.user_detail_view table th img {margin-left:6px;vertical-align:middle;}

.schedule_content_wrap {width:460px;height:415px;float:left;border:1px solid #e7e7e7;border-left:0;border-top-right-radius:3px;border-bottom-right-radius:3px;position:relative;}
.schedule_content_wrap .ReactTable .rt-tbody .rt-tr-group {padding: 0px;}
.detail_view {position:relative;}

.detail_btn {border:0;background:none;color:#8d8d8d;line-height:28px;padding:0;text-align:left}
.detail_btn > span {width:7px;height:5px;display:inline-block;background:url('../../../images/button/select_down.png') no-repeat;vertical-align:middle;margin-right:9px;}
.detail_btn.on > span {background:url('../../../images/button/select_up.png') no-repeat;}
.txt_box_1 {line-height:20px;font-size:13px;color:#818181;border:1px solid #e7e7e7;border-radius:3px;padding:7px 24px;background:#fcfcfd}
.txt_box_2 {line-height:19px;font-size:13px;color:#818181;border:1px solid #e7e7e7;border-radius:3px;padding:7px 24px;background:#fcfcfd}
.txt_box_3 {line-height:19px;font-size:13px;color:#818181;border:1px solid #e7e7e7;border-radius:3px;padding:7px 0px;background:#fcfcfd}

.error_txt2 {width:100%;color:#f79394;line-height:13px;}
.error_txt3 {position: absolute;top:0px;left: 275px;width:100%;color:#f79394;display:inline-block;line-height:13px;padding-top:5px;padding-left:4px}
.error_txt4 {color:red;}

.change_organization_wrap {width:460px;height:415px;float:left;border:1px solid #e7e7e7;border-left:0;border-top-right-radius:3px;border-bottom-right-radius:3px;position:relative;}
.change_organization_wrap .ReactTable .rt-tbody .rt-tr-group {padding: 0px;}
.change_organization_wrap .ReactTable .rt-tbody .rt-tr-group .rt-td {height: 26px;}

.ldap_select_wrap {width:460px;height:415px;float:left;border:1px solid #e7e7e7;border-left:0;border-top-right-radius:3px;border-bottom-right-radius:3px;position:relative;}
.ldap_select_wrap .ReactTable .rt-tbody .rt-tr-group {padding: 0px;}
.ldap_select_wrap .ReactTable .rt-tbody .rt-tr-group .rt-td {height: 35px;}

.passwordResetPopup html, body {font-family:Tahoma, Arial, Dotum,sans-serif;height:100%;color:#818181;font-size:12px;line-height:16px;position: relative;overflow-x:hidden;overflow-y:hidden;}
.passwordResetPopup table {width:100%;}
.passwordResetPopup table th {vertical-align:middle;color:#8d8d8d;padding-right:10px;}
.passwordResetPopup table td {padding:6px 0 5px 0;vertical-align:middle;color:#474747;height:28px;}

.mis_dialog.userGroup .ui-dialog-overlay {z-index: 9998 !important;}
.mis_dialog.userGroup .ui-dialog {z-index: 9999 !important;}

.mis_dialog.removeUser .ui-dialog-overlay {z-index: 99998 !important;}
.mis_dialog.removeUser .ui-dialog {z-index: 99999 !important;}

.user_detail_view .select_input .base.select:disabled {cursor: default;}
.change_organization_wrap .rt-th > div {text-align: center;}