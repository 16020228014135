.preset_publish_status_wrap .ui-dialog {
    z-index: 101;
}

.preset_publish_status_wrap .ui-dialog-overlay {
    z-index: 100;
}

.preset_publish_status_wrap .detail_view table th {
    padding-left: 0px;
}

.preset_publish_status_wrap .uploading {
    position: relative;
    background: #e8e8e8;
    width: 100%;
    height: 19px;
    margin-top: 49px;
}

.preset_publish_status_wrap .uploading > .loading_status {
    background: #4be48c;
    height: 19px;
    width: 100%;
    display: block;
    top: 0;
    left: 0;
    color: #fff;
    line-height: 19px;
    text-align: right;
}

.preset_publish_status_wrap .uploading > .loading_status > span {
    font-size: 14px;
    display: inline-block;
    margin-right: 7px;
}

.preset_publish_status_wrap .uploading > .loading_status > span > span {
    font-size: 12px;
}

.preset_publish_status_wrap .uploading .file_total {
    position: absolute;
    right: 5px;
    top: -25px;
    font-size: 12px;
    color: #848484;
}

.preset_publish_status_wrap .uploading .total_status_txt {
    position: absolute;
    top: -33px;
    color: #666666;
}

.preset_publish_status_wrap .uploading .loading_value {
    position: absolute;
    margin-left: 300px;
    color: #fff;
}

.preset_publish_status_wrap .detail_view .content_size {
    color: #848484;
}

.preset_publish_status_wrap .detail_btn {
    border: 0;
    background: none;
    color: #8d8d8d;
    line-height: 28px;
    padding: 0;
    text-align: left;
}

.preset_publish_status_wrap .detail_btn > span {
    width: 7px;
    height: 5px;
    display: inline-block;
    background: url('../../../../images/button/select_down.png') no-repeat;
    vertical-align: middle;
    margin-right: 9px;
}

.preset_publish_status_wrap .detail_btn.on > span {
    background: url('../../../../images/button/select_up.png') no-repeat;
}

.preset_publish_status_wrap .publish_status_detail_table {
    border-color: grey;
    border-left: 1px solid #e7e7e7;
    border-right: 1px solid #e7e7e7;
    border-bottom: 1px solid #e7e7e7;
    border-bottom-left-radius: 2px;
    font-size: 13px;
    overflow-x: auto;
    overflow-y: auto;
    max-height: 260px;
}

.preset_publish_status_wrap .publish_status_detail_table th {
    color: #797979;
    vertical-align: middle;
    border-top: 1px solid #d2d2d3;
    border-bottom: 1px solid #d2d2d3;
    padding: 7px 10px 7px 10px !important;
    background: url('../../../../images/bg/table_divider.png') no-repeat right #eeeeef;
}

.preset_publish_status_wrap .publish_status_detail_table td {
    height: 20px;
    border-right: 1px solid #f2f2f2;
    border-bottom: 1px solid #f2f2f2;
    vertical-align: middle;
    text-align: left;
    padding: 9px 10px 9px 10px;
    line-height: 20px;
    position: relative;
}