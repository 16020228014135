.tag_setting_popup_wrap .tag_setting_header {
    height: 45px;
}

.tag_setting_popup_wrap .tag_setting_header .small > span:nth-of-type(1){
    margin-right: 70px;
}

.tag_setting_popup_wrap .tag_setting_header .leftButton {
    margin-left: 0px;
    float:left;
}

.tag_setting_popup_wrap .tag_setting_header .leftButton > span {
    margin-right: 70px;
}

.tag_setting_popup_wrap .tag_setting_header .rightButton {
    margin-right: 30px;
    float:right;
}

.tag_setting_popup_wrap .content_tag_wrap {
    min-height: 80px;
    max-height: 80px;
    width: 490px;
    padding: 10px;
    overflow-y: auto;
    border: 0;
}

.tag_setting_popup_wrap .content_tag_wrap .tag_round_wrap {
    position: relative;
    display: inline-block;
    padding: 0 22px 0 7px !important;
    background: #eff2f6;
    border: 1px solid #D6DEE8;
    border-radius: 15px;
    line-height: 26px;
    margin-bottom: 8px;
    margin-right: 8px;
}

.tag_setting_popup_wrap .content_tag_wrap .tag_round {
    width: 80px;
    height: 28px;
    border: 1px solid #eff2f6;
    color: #686b72;
    background: #eff2f6;
    font-size: 12px;
    line-height: 24px;
    text-align: left;
    outline: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 0px;
}

.tag_setting_popup_wrap .tag_setting_body .tag_setting_device_list {
    margin-left: 0px;
    float:left;
    width: 250px;
    border: solid 1px;
    border-color: #dfe2e6;
    height: 99%;
}

.tag_setting_popup_wrap .tag_setting_body .tag_setting_device_list .device_list_title {
    background: #eff2f6;
    padding: 8px;
    border-bottom: solid 1px;
    border-color: #dfe2e6;
}

.tag_setting_popup_wrap .tag_setting_body .tag_setting_device_list .select_group_btn {
    float: right;
    border: 0;
    background: url('../../../images/button/btn_group.png') no-repeat;
    width: 24px;
    height: 24px;
    cursor: pointer;
    background-position-y: -5px;
}

.tag_setting_popup_wrap .tag_setting_body .tag_setting_device_list .device_list_data {
    height: 91%;
    overflow-y: auto;
}

.tag_setting_popup_wrap .tag_setting_body .tag_setting_device_list .device_list_data li {
    margin: 15px;
    border: none;
    background-color: #eff2f6;
    border-radius: 20px;
    color: #686b72;
    list-style-position: inside;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    padding: 5px 30px 5px 20px;
}

.tag_setting_popup_wrap .tag_setting_body .tag_setting_device_list .device_list_data li > .delete_btn {
    float: right;
    border: 0;
    background: url('../../../images/icon/dashboard_delete_normal.png') no-repeat;
    width: 19px;
    height: 19px;
    position:absolute;
    right:10px;
    top:4px;
}

.tag_setting_popup_wrap .tag_setting_body .tag_setting_device_list .device_list_data li > .delete_btn:hover {
    background:url('../../../images/icon/dashboard_delete_hover.png') no-repeat;
}

.tag_setting_popup_wrap .tag_setting_body .tag_setting_device_list .device_list_data li > .delete_btn:active {
    background:url('../../../images/icon/dashboard_delete_select.png') no-repeat;
}

.tag_setting_popup_wrap .tag_setting_body {
    height: 375px;
}

.tag_setting_popup_wrap .height_small {
    height: 260px;
}

.tag_setting_popup_wrap .tag_setting_body .tag_setting_tag_list {
    margin-right: 30px;
    float:right;
}

.tag_setting_popup_wrap .tag_setting_body .tag_setting_tag_list .checkboxWrap > input[type='checkbox'] + label {
    height: 16px;
    background: url(../../../images/img/checkbox_normal.png) no-repeat;
    vertical-align: middle;
}

.tag_setting_popup_wrap .tag_setting_body .tag_setting_tag_list .checkboxWrap > input[type='checkbox']:checked + label {
    height: 16px;
    background: url(../../../images/img/checkbox_checked.png) no-repeat;
    vertical-align: middle;
}

.tag_setting_popup_wrap .tag_setting_body .tag_setting_tag_list .content_tag_view_btn {
    background: url(../../../images/button/btn_detail_nor.png) no-repeat right center;
    width: 28px;
    height: 28px;
    border-radius: 15px;
    border: 1px solid #dde0e5;
}

.tag_setting_popup_wrap .tag_setting_body .tag_setting_tag_list .content_tag_view_btn:hover {
    background: url(../../../images/button/btn_detail_over.png) no-repeat right center;
    cursor: pointer;
}

.tag_setting_popup_wrap .tag_setting_body .tag_setting_tag_list .select_input > span {
    font-size: 12px;
}

.tag_setting_popup_wrap .tag_setting_body .tag_setting_tag_list .tag_condition_btn span > .arrow {
    display: inline-block;
    float: right;
    width: 10px;
    height: 20px;
    background: url('../../../images/button/select_down.png') no-repeat 0 10px;
    margin-left: 10px;
}

.tag_setting_popup_wrap .tag_setting_body .tag_setting_tag_list .tag_condition_input:disabled {
    background: #f3f3f3;
    border-color: #f3f3f3;
}

.tag_setting_popup_wrap .tag_setting_body .tag_setting_tag_list .tag_condition_btn {
    border: 1px solid #dde0e5;
    border-radius: 5px;
    padding-left: 9px;
    padding-right: 6px;
    text-align: left;
    width: 100%;
    background: #fff;
    font-size: 12px;
    line-height: 24px;
    cursor: pointer;
}

.tag_setting_popup_wrap .tag_setting_body .tag_setting_tag_list .tag_condition_btn:hover {
    border-color: #b4b9c3;
    background: #e7eaf0;
    color: #848484;
    cursor: pointer;
 }

.tag_setting_popup_wrap .tag_setting_body .tag_setting_tag_list .tag_condition_btn:disabled {
    color: #c1c1c1;
    border-color: #f3f3f3;
    background: #f3f3f3;
}

/*.tag_setting_popup_wrap .tag_setting_body .tag_setting_tag_list .tag_condition_option_list {
    width: 100%;
    height: 100px;
    !*max-width: 100px;*!
    position: fixed;
    background: #fff;
    border: 1px solid #dde0e5;
    box-shadow: #dedede 0 1px 0;
    z-index: 1;
    min-width: 190px;
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
}

.tag_setting_popup_wrap .tag_setting_body .tag_setting_tag_list .tag_condition_option_list span {
    padding: 6px 5px 4px 12px;
    display: block;
}

.tag_setting_popup_wrap .tag_setting_body .tag_setting_tag_list .tag_condition_option_list > ul > li {
    padding:6px 5px 4px 12px;
    font-size:12px;
    position:relative;
    text-align:left;
    display: flex;
    line-height: 16px;
}

.tag_setting_popup_wrap .tag_setting_body .tag_setting_tag_list .tag_condition_option_list > ul > li > input {
    display: none;
}
.tag_setting_popup_wrap .tag_setting_body .tag_setting_tag_list .tag_condition_option_list > ul > li > label {
    padding-left: 25px;
}

.tag_setting_popup_wrap .tag_setting_body .tag_setting_tag_list .tag_condition_option_list > ul > li > input[type="checkbox"] + label {
    background: url(../../../images/img/checkbox_normal.png) no-repeat;
    cursor: pointer;
}
.tag_setting_popup_wrap .tag_setting_body .tag_setting_tag_list .tag_condition_option_list > ul > li > input[type="checkbox"].on + label {
    background: url(../../../images/img/checkbox_checked.png) no-repeat;
}

.tag_setting_popup_wrap .tag_setting_body .tag_setting_tag_list .tag_condition_option_list > ul > li > input[type="radio"] + label {
    display:inline;
    padding-left:24px;
    color:#59616b;
    background:url('../../../images/img/radio_normal.png') no-repeat;
    padding-bottom:2px;
    cursor:pointer;
}

.tag_setting_popup_wrap .tag_setting_body .tag_setting_tag_list .tag_condition_option_list > ul > li > input[type="radio"]:checked + label {
    background: url('../../../images/img/radio_checked.png') no-repeat;
}

.tag_setting_popup_wrap .tag_setting_body .tag_setting_tag_list .tag_condition_option_list > ul > li:first-child {padding-top:11px}
.tag_setting_popup_wrap .tag_setting_body .tag_setting_tag_list .tag_condition_option_list> ul > li:last-child {border-bottom:0;padding-bottom:10px}
.tag_setting_popup_wrap .tag_setting_body .tag_setting_tag_list .tag_condition_option_list> ul > li.last {border-top:1px solid #DDE0E5;width:132px;border-bottom:0;padding-bottom:10px;}*/

.tag_name_cell {
    white-space: nowrap;
    word-wrap: break-word;
    word-break: break-all;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
}

.tag_name_block {

}

.tag_setting_popup_wrap .tag_setting_body .tag_setting_tag_list .rt-th:first-child {
    margin-left: 10px;
}

.tag_setting_popup_wrap .tag_setting_body .tag_setting_tag_list .ReactTable .rt-thead .rt-th:last-child {
    margin-right: 20px;
}