.lnbWrap .lnb .divider {
    width: 206px;
    height: 1px;
    background: #555c6b;
    margin-left: 16px;
    position: absolute;
    display: inline-block;
}
.main_lnb .divider {
    width: 90%;
    height: 1px;
    background: #555c6b;
    margin-left: 16px;
    position: absolute;
    display: inline-block;
}
.lnbSubMenu {
    position: relative;
    margin: 0;
    padding:0;
}
.lnb_buttonWrap > * {
    margin-left: 10px;
    float: left;
}

.lnb > .lnbSubMenu > .lnbMenu {
    line-height: 41px;
    width: 100%;
    cursor: pointer;
}


.lnb > ul > li.active > a {
    color: #3196ff;
}
.lnb > ul > li.active.hasSub > a {
    color: #3196ff;
}

.lnb > ul > li > ul > li {
    line-height: 39px;
    width: 100%;
    background-position: right 26px top 17px;
    color: #dedede;
}
.lnb > ul > li > ul > li.active > a {
    color: #3196ff;
}
.lnb > ul > li > ul > .active.hasSub > a {
    color: #3196ff;
}
.lnb > ul > li > ul > li > a {
    padding-left: 38px;
    font-size: 12px;
    color: #dedede;
    width: 100%;
    box-sizing: border-box;
    text-decoration: none;
}
.lnb > ul > li.hasSub:hover {
    background:#2a303b;
    background-position: right 26px top 17px;
    width:100%;
}

/* 2th sub menu*/
.lnb > ul > li > ul.open {
    display: block;
}
.lnb > ul > li > ul.close {
    display: none;
}

.lnb .numbering {min-width:22px;text-align:center;font-size:11px;color:#fff;display:inline-block;height:16px;background:#a4a8ae;border-radius:8px;line-height:16px;padding-left:6px;padding-right:6px;margin-left:15px;}
/*.lnb a:hover .numbering {color:#fff;background:#609be8;}*/
.lnb a:active .numbering {color:#fff;background:#3196ff;}
.lnb li.active .numbering {color:#fff;background:#3196ff;}
.lnb li {position: relative;}
.numbering.loading {} /* be able to add loading icon if UX wants in advanced*/
.numbering.zero {display:none;}

/*.lnb > ul > li > a:hover {background:#2a303b;color:#dedede}
.lnb > ul > li:hover {background:#2a303b;}*/

.lnb > ul li.hasSub > a:hover {background:url('../images/bg/menu_arrow_over.png') no-repeat;background-position: right 26px top 17px;/* color:#5f6366; */}

.lnb > ul li > a {
    box-sizing: border-box;
    padding-left: 28px;
    font-size: 14px;
    color: #dedede;
    background: none;
    width: 100%;
    text-decoration: none;
    display: inline-block;
}
.lnb li {
    position: relative;
}
li {
    list-style: none;
}
ul, li {
    margin: 0;
    padding: 0;
}

/* tree */
.lnb .tree {
    margin-left: 40px;
    line-height: normal;
}

.lnbWrap .ps__rail-y { z-index:100; }

.lnb > ul > li > a > .iconwrap {
    right: 16px;
    display: inline-block;
    height: 19px;
    position: absolute;
    top: 7px;
}

.lnb > ul > li > a > .iconwrap button {
    border: 0;
    float: left;
    padding: 0;
    margin: 0;
}

.lnb > ul > li > a > .iconwrap .basket {
    height: 27px;
    width: 27px;
    background: url('../images/icon/lnb_icon2.png') no-repeat 0 0;
}

.lnb > ul > li > a > .iconwrap .basket:focus {
    outline: none !important;
}

.lnb > ul > li > a > .iconwrap .basket:hover {
    background:url('../images/icon/lnb_icon2.png') no-repeat 0 -27px;
    cursor: pointer;
}

.lnb > ul > li > a > .iconwrap .basket:active {
    background:url('../images/icon/lnb_icon2.png') no-repeat 0 -54px;
}

.lnb a > .iconwrap {
    display: inline-block;
    height: 19px;
    position: absolute;
    top: 7px;
}

.lnb a > .iconwrap .basket {
    height: 27px;
    width: 27px;
    background: url('../images/icon/lnb_icon2.png') no-repeat 0 0;
}
.lnb a > .iconwrap button {
    border: 0;
    float: left;
    padding: 0;
    margin: 0;
    cursor: pointer;
}

.lnb a > .iconwrap .basket:hover {background:url('../images/icon/lnb_icon2.png') no-repeat 0 -27px;}
